import './App.css';
import React from 'react';
import CapabilityMap from './assets/capability_map.png';
import KoFi from './assets/kofi_icon.png';
import GitHub from './assets/github.svg';
import Ngrok from './assets/ngrok-white-lrg.png';
import Lon from './assets/Lon Anthro.png';
import Export from './assets/file-export-solid.svg';
import HandHeart from './assets/hand-holding-heart-solid.svg';
import Nodes from './assets/circle-nodes-solid.svg';

class App extends React.Component{
  constructor(props){
    super(props);
  }

  gotoGithub(){
	window.location.href = "https://github.com/GreySole/Spooder";
  }

  gotoKofi(){
	window.location.href = "https://ko-fi.com/greyfursole"
  }

  scrollToTop(){
	document.querySelector(".content").scrollTop = 0;
  }

  render(){
    return (
      <div className="App">
        <div className="navigation">
			<div className="banner-logo" onClick={this.scrollToTop}>/╲/\( ºO ω Oº )/\╱\</div>
			<div className="button-row">
				<div className="button-panel" onClick={this.gotoGithub}>
					<div className="link-button github"><img src={GitHub} height="20px"/> Clone</div>
				</div>
				<div className="button-panel" onClick={this.gotoKofi}>
					<div className="link-button kofi"><img src={KoFi} height="20px"/> Support</div>
				</div>
			</div>
		</div>
		<div className="content">
			<div className="content-page">
				<div className="page-header">
					<div className="banner-logo">This is Spooder</div>
				</div>
				<div className="welcome-image-panel"><img src={CapabilityMap}/></div>
				<div className="welcome-footer">An open source personal bot with all the integrations you need. Right at home!</div>
			</div>
			<div className="content-page">
				<div className="page-header">You own it!</div>
				<div className="panel-duo">
					<div className="image-panel"><img src={Lon}/></div>
					<div className="text-panel">
						<p>
							Credentials for Spooder's integrations come from your dev console.
							Give it a name. Give it a profile picture. It's your very own custom bot!
						</p>
						<p>
							This is LonTheWeaver. He works for GreyBoiGaming on Twitch. Don't worry, he won't bite.
							He runs the whole stream with OSC controls, chat commands, channel point rewards, Twitch events, and OBS remote built in.
							He even has auto chat translating and voice triggers! Both of which are plugins you can download and install free on your own Spooder through Grey Sole's KoFi page.
						</p>
						<p>
							It's alright, your Spooder doesn't have to be a spider character like Lon. The app is just the organs of the character. Any body can contain it :3
						</p>
					</div>
				</div>
			</div>
			<div className="content-page">
				<div className="page-header">Develop in all areas simple and complex</div>
				<div className="panel-duo">
					<div className="image-panel"><img style={{"filter":"invert()"}} src={Nodes}/></div>
					<div className="text-panel">
						<div className="list">
							Spooder's WebUI lets you set multiple triggers.
							<ul>
								<li>Twitch Chat</li>
								<li>Twitch Channel Point Rewards</li>
								<li>Twitch Events</li>
								<li>OSC Interfaces</li>
							</ul>
							And output to many outlets!
							<ul>
								<li>Custom chat scripts</li>
								<li>OSC Software</li>
								<li>OBS</li>
								<li>Moderation</li>
								<li>Plugin events</li>
							</ul>
							*Discord is integrated separately from the event system. You can make plugins that take messages and voice chat events.
							Discord is also used to send a message when you're live and auto send Spooder's Ngrok link to a mod channel on your server.
						</div>
						
					</div>
				</div>
			</div>
			<div className="content-page">
				<div className="page-header">Make unique stream features and easily share them</div>
				<div className="panel-duo">
					<div className="image-panel"><img style={{"filter":"invert()"}} src={HandHeart}/></div>
					<div className="text-panel">
						<p>Demo your plugins before making the sale. Be the cool nerd in a group of streamer friends. 
							You can share easily in just a few clicks with no installations on their end!
						</p>
						<p>
							Exporting plugins for distribution is even easier with one click. Ready to be installed in one click.
						</p>
					</div>
				</div>
			</div>
			<div className="content-page">
				<div className="page-header">What are Spooder Plugins?</div>
				<div className="panel-duo">
					<div className="image-panel"><img style={{"filter":"invert()"}} src={Export}/></div>
					<div className="text-panel">
						<p>
							They're Node.js files that can contain their own dependencies, assets, and web pages. 
							Their functions are called within Spooder's integrations such as Twitch, OSC, Spooder Events, and Discord.
							You can make custom overlay pages for streaming software and utility pages to interface with them.
						</p>
						<p>
							Development for public pages for all viewers to interact is currently underway!
						</p>
					</div>
				</div>
			</div>
			<div className="content-page">
				<div className="page-header">Host Publicly on The Internet</div>
				<div className="panel-duo">
					<div className="image-panel"><img src={Ngrok}/></div>
					<div className="text-panel">
						<p>
							You may want to keep your Spooder private on your LAN, but some integrations need HTTPS to work (Twitch Event Subscriptions). 
							With Ngrok integrated, you can securely tunnel requests from the internet and keep the URL a secret.
							This way you can also share utilities to your moderators, and overlays your shares with a URL that changes every time Spooder is started.
						</p>
						<p>
							*Currently in development*
							Project Motherwolf will be a cheap service that will offer subdomains to securely tunnel requests and make UI available to the public.
							Spooder is looking at integrations with other streaming platforms like YouTube and Kick (when the API is available). 
							You'll be able to have your rewards and commands available on any platform with a Motherwolf link!
						</p>
					</div>
				</div>
			</div>
			<div className="content-page">
				<div className="page-header">Try it. It's open source!</div>
				<div className="stack-panel">
					<div className="button-row">
						<div className="button-panel" onClick={this.gotoGithub}>
							<div className="link-button github"><img src={GitHub} height="40px"/> Clone on GitHub</div>
						</div>
						<div className="button-panel" onClick={this.gotoKofi}>
							<div className="link-button kofi"><img src={KoFi} height="40px"/> Support me on Ko-Fi</div>
						</div>
					</div>
				</div>
			</div>
		</div>
      </div>
    );
  }
}

export default App;